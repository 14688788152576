<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-btn small @click="pushRouteToView('bmFinance')" class="mr-4 btn-back">
            <v-icon>
              mdi-arrow-left
            </v-icon>
            Voltar
          </v-btn>
          <v-icon class="mr-2">mdi-cash-multiple</v-icon>
          Solicitação de Pagamento
          <v-spacer></v-spacer>

        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-card-account-details-outline</v-icon>
              CNPJ:</b> {{ bmWithdrawalCash.cnpj }}
            </v-col>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-office-building</v-icon>
              Razão Social:</b> {{ bmWithdrawalCash.companyName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-currency-usd</v-icon>
              Saldo Liberado:</b> {{ bmWithdrawalCash.valueReleased | money }}
            </v-col>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-currency-usd</v-icon>
              Saldo a Liberar:</b> {{ bmWithdrawalCash.valueAnticipation | money }}
            </v-col>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-currency-usd</v-icon>
              Valor Líquido:</b> {{ bmWithdrawalCash.totalValue | money }}
            </v-col>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-calendar</v-icon>
              Data de solicitação:</b> {{ bmWithdrawalCash.requestDate | formatDate3 }}
            </v-col>
            <v-col md="4" sm="6"><b>
              <v-icon>mdi-calendar</v-icon>
              Chave Pix:</b> {{ bmWithdrawalCash.pixkey }}
            </v-col>
            <v-col md="4" sm="6"><b>
              <v-btn
                icon
                @click="copyToClipboard(getQrcodePix(bmWithdrawalCash))"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              Pix Copia e Cola:</b> {{ getQrcodePix(bmWithdrawalCash) }}
            </v-col>
            <v-col md="4" sm="6" v-show="bmWithdrawalCash.payDate"><b>
              <v-icon>mdi-calendar</v-icon>
              Data de pagamento:</b>
              {{ bmWithdrawalCash.payDate | formatDate3 }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                outlined
                v-show="bmWithdrawalCash.paid"
                class="mx-1 btn-secondary"
                @click="downloadTicket()">
                <v-icon left>mdi-download</v-icon>
                Download
              </v-btn>
            </v-col>
          </v-row>
          <v-form
            ref="confirmPayForm"
            v-model="formConfirmPayIsValid"
            v-on:submit.prevent="confirmPay()"
            v-show="!bmWithdrawalCash.paid"
          >
            <v-row>

              <v-col>
                <v-file-input
                  v-model="file"
                  accept="application/pdf"
                  placeholder="Escolha Um Arquivo"
                  prepend-inner-icon="mdi-file-pdf-box"
                  prepend-icon=""
                  label="Escolha Um Arquivo"
                  outlined
                  :rules="pdfRules"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-btn
                  class="btn-save mt-2"
                  type="submit"
                >
                  <v-icon left>mdi-cash-check</v-icon>
                  Confirmar Pagamento
                </v-btn>
                
              </v-col>
              <v-col>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {QrCodePix} from 'qrcode-pix';
import md5 from 'md5';

import {BM_FINANCE_GET} from "@/store/actions/bm_finance.type";
import {BM_FINANCE_RESET_STATE} from "@/store/mutations/bm_finance.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

import {BMFinanceService} from "@/common/api/bm_finance.service";

export default {
  name: "bmFinanceView",

  data: () => ({
    file: null,
    formConfirmPayIsValid: false,
    pdfRules: [
      value => !value || value.size < 10000000 || 'O tamanho máximo do arquivo é 10MB',
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.type !== "application/pdf") {
            return 'Envie um arquivo PDF'
          }
        }
        return true
      }
    ],

  }),

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BM_FINANCE_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BM_FINANCE_RESET_STATE),
      store.dispatch(BM_FINANCE_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },

  methods: {
    async copyToClipboard(text){
      await navigator.clipboard.writeText(text)
      this.snackbar.snackbar = true;
      this.snackbar.text = "Copiado para área de transferência";
      this.snackbar.color = "success";
    },

    ehEmail(pix) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(pix);
    },
    validarCPF(cpf) {
      cpf = cpf.replace(/\D/g, ""); // Remove caracteres não numéricos
      if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false; // Evita CPFs como "111.111.111-11"

      let soma = 0;
      for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
      let resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.charAt(9))) return false;

      soma = 0;
      for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) resto = 0;
      return resto === parseInt(cpf.charAt(10));
    },
    
    getQrcodePix(bmWithdrawalCash) {

      let chavePixFormatada = () => {
        if (this.ehEmail(bmWithdrawalCash.pixkey)) {
          // Se for um e-mail, mantém o valor original
          return bmWithdrawalCash.pixkey;
        } else {
          // Remove caracteres especiais (para CPF e CNPJ)
          let cleanValue = bmWithdrawalCash.pixkey.replace(/[.\-/]/g, "");

          if (/^\d{11}$/.test(cleanValue)) {
            // Se tiver 11 dígitos, verifica se é CPF válido
            if (this.validarCPF(cleanValue)) {
              return cleanValue; // Mantém como CPF
            } else {
              return `+55${cleanValue}`; // Não é CPF, assume que é telefone
            }
          } else {
            // Apenas mantém o valor limpo (CNPJ ou qualquer outro número)
            return cleanValue;
          }
        }
      }
      
      let qrCodePix = QrCodePix({
        version: '01',
        key: chavePixFormatada(), //or any PIX key
        name: bmWithdrawalCash.companyName,
        city: 'Curitiba',
        transactionId: md5(bmWithdrawalCash.id).substring(0, 25), //max 25 characters
        message: 'BM Payment',
        cep: '00000000',
        value: parseFloat(bmWithdrawalCash.totalValue),
      });

      return qrCodePix.payload()
    },
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    downloadTicket() {
      this.$store.commit(CHANGE_PROGRESS, true)
      BMFinanceService.confirmPayFile(this.bmWithdrawalCash.id).then((response) => {

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `payment_${this.bmWithdrawalCash.id}ludsyln.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        this.$store.commit(CHANGE_PROGRESS, false)
      })
        .catch((err) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.text = `Não foi possível alterar status - ${err.status}`;
          this.snackbar.color = "error";
        });
    },
    async confirmPay() {
      if (this.$refs.confirmPayForm.validate()) {

        store.commit(CHANGE_PROGRESS, true);
        BMFinanceService.sendConfirmPay(this.bmWithdrawalCash.id, this.file)
          .then(async () => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";

            await store.dispatch(BM_FINANCE_GET, this.bmWithdrawalCash.id)

          })
          .catch(({response}) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar.";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar : " + response.data.message;
            }
          });

      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }

    },
  },

  computed: {
    ...mapGetters(["bmWithdrawalCash", "checkUsers", "snackbar"])
  }
};
</script>
